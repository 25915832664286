<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
      <q-form ref="editForm">
        <c-card title="보호구 신청 상세" class="cardClassDetailForm">
          <template slot="card-detail">
            <div class="col-3">
              <c-datepicker
                label='신청일'
                default="today"
                :disabled="true"
                :editable="editable"
                name="requestDt"
                v-model="data.requestDt"
              />
            </div>
            <div class="col-3">
              <c-field
                label='신청자'
                :disabled="true"
                :editable="editable"
                name="requestUserId"
                v-model="data.requestUserId"
              />
            </div>
            <div class="col-3">
              <c-dept
                label='신청부서'
                :disabled="true"
                :editable="editable"
                name="requestDeptCd"
                v-model="data.requestDeptCd"
              />
            </div>
          </template>
        </c-card>
      </q-form>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
      <q-form ref="editForm">
        <c-card title="보호구 지급 상세" class="cardClassDetailForm">
          <template slot="card-button">
            <q-btn-group outline>
              <c-btn v-if="editable" label="지급완료" icon="save" @btnClicked="completeRequest" />
              <c-btn v-if="editable" label="LBLSAVE" icon="save" @btnClicked="saveRequest" />
            </q-btn-group>
          </template>
          <template slot="card-detail">
            <div class="col-3">
              <c-plant
                :required="true"
                :disabled="disable"
                :editable="editable"
                type="edit"
                name="plantCd"
                v-model="data.plantCd"
              />
            </div>
            <div class="col-3">
              <c-datepicker
                :required="true"
                label='지급일'
                :disabled="disable"
                :editable="editable"
                name="giveDt"
                v-model="data.giveDt"
              />
            </div>
            <div class="col-3">
              <c-field
                :required="true"
                :disabled="disable"
                label='불출담당자'
                :editable="editable"
                name="giveUserId"
                v-model="data.giveUserId"
              />
            </div>
            <div class="col-3">
              <c-dept
                :required="true"
                label='지급부서'
                :disabled="disable"
                :editable="editable"
                name="giveDeptCd"
                v-model="data.giveDeptCd"
              />
            </div>
          </template>
        </c-card>
      </q-form>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
      <c-table
        ref="table"
        title="보호구 신청 목록"
        tableId="table"
        :columns="grid.columns"
        :data="grid.data"
        :merge="grid.merge"
        :isFullScreen="false"
        :columnSetting="false"
        :filtering="false"
        :usePaging="false"
        :isExcelDown="false"
        :editable="false"
        gridHeight="600px"
        selection="multiple"
        rowKey="safetySuppliesGiveId"
      >
      </c-table>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
      <c-table
        ref="table"
        title="보호구 지급 목록"
        tableId="table"
        :columns="grid2.columns"
        :data="grid2.data"
        :merge="grid2.merge"
        :isFullScreen="false"
        :columnSetting="false"
        :filtering="false"
        :usePaging="false"
        :isExcelDown="false"
        gridHeight="600px"
        selection="multiple"
        rowKey="safetySuppliesGiveId"
        :plantCd="data.plantCd"
      >
        <!-- 버튼 영역 -->
        <template slot="table-button">
          <div>
            <q-btn-group outline>
              <c-btn v-if="editable" label="LBLADD" icon="add" @btnClicked="addrowGive" />
              <c-btn v-if="editable" label="LBLREMOVE" icon="remove" @btnClicked="removeGive" />
            </q-btn-group>
          </div>
        </template>
      </c-table>
    </div>
     <!-- <div class="col-xs-12 col-sm-12 col-md-1 col-lg-1 col-xl-1" style="margin: auto 0; line-height: 35px; text-align:center;">
      <c-btn v-if="editable" label="선택추가" icon="arrow_back_ios" color="blue-7" />
      <c-btn v-if="editable" label="전체추가" icon="arrow_back_ios" color="black" /><br><br>
      <c-btn v-if="editable" label="선택제외" icon="arrow_forward_ios" color="red" />
      <c-btn v-if="editable" label="전체제외" icon="arrow_forward_ios" color="black" />
    </div> -->
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import CDatepicker from '../../../components/CDatepicker.vue';
export default {
  components: { CDatepicker },
  name: 'safety-give-manage',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        safetySuppliesGiveId: '',
        plantCd: '',
      }),
    },
  },
  data() {
    return {
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      data: {
        plantCd: null,
        requestDt: '2022-09-02',
        requestDeptCd: '',
        requestUserId: 'U000000001',
        giveDt: '2022-09-03',
        giveDeptCd: '',
        giveUserId: 'U000000001',
      },
      grid: {
        columns: [
          {
            name: 'col1',
            field: 'col1',
            label: '보호구종류',
            style: 'width:120px',
            align: 'left',
            type: 'select',
            comboItems: [
              { code: '1', codeName: '안전모' },
              { code: '2', codeName: '안전화' },
              { code: '3', codeName: '안전장갑' },
              { code: '4', codeName: '마스크' },
              { code: '5', codeName: '보호구' },
              { code: '6', codeName: '보호복' },
            ],
            setHeader: true,
            sortable: false,
          },
          {
            name: 'col2',
            field: 'col2',
            label: '보호구명',
            style: 'width:200px',
            align: 'left',
            type: 'text',
            setHeader: true,
            sortable: false,
          },
          {
            name: 'col3',
            field: 'col3',
            label: '신청수량',
            style: 'width:80px',
            align: 'right',
            type: 'number',
            setHeader: true,
            sortable: false,
          },
          {
            name: 'col4',
            field: 'col4',
            label: 'LBLREMARK',
            align: 'left',
            type: 'text',
            setHeader: true,
            sortable: false,
          },
        ],
        data: [],
      },
      grid2: {
        columns: [
          {
            name: 'col1',
            field: 'col1',
            label: '보호구종류',
            style: 'width:120px',
            align: 'left',
            type: 'select',
            comboItems: [
              { code: '1', codeName: '안전모' },
              { code: '2', codeName: '안전화' },
              { code: '3', codeName: '안전장갑' },
              { code: '4', codeName: '마스크' },
              { code: '5', codeName: '보호구' },
              { code: '6', codeName: '보호복' },
            ],
            setHeader: true,
            sortable: false,
          },
          {
            name: 'col2',
            field: 'col2',
            label: '보호구명',
            style: 'width:200px',
            align: 'left',
            type: 'text',
            setHeader: true,
            sortable: false,
          },
          {
            name: 'col3',
            field: 'col3',
            label: '지급수량',
            style: 'width:80px',
            align: 'right',
            type: 'number',
            setHeader: true,
            sortable: false,
          },
          {
            name: 'col4',
            field: 'col4',
            label: 'LBLREMARK',
            align: 'left',
            type: 'text',
            setHeader: true,
            sortable: false,
          },
        ],
        data: [],
      },
      typeItems: [
        { code: 'Y', codeName: '전체' },
        { code: 'N', codeName: '일자별' },
      ],
      searchParam: {
        plantCd: null,
        giveDt: '',
        safetySuppliesGiveId: '',
      },
      editable: true,
      disable: false,
      listUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {},
  methods: {
    init() {
      this.getList();
    },
    getList() {
      this.grid.data = [
        {
          col1: '1',
          col2: '안전모',
          col3: '10',
          col4: '',
        },
        {
          col1: '2',
          col2: '발등안전화',
          col3: '10',
          col4: '',
        },
        {
          col1: '3',
          col2: '안전 방진장갑',
          col3: '10',
          col4: '',
        },
        {
          col1: '4',
          col2: '방진마스크',
          col3: '5',
          col4: '',
        },
        {
          col1: '6',
          col2: '내전압용 절연복',
          col3: '5',
          col4: '',
        },
      ]
      this.grid2.data = [
        {
          col1: '1',
          col2: '안전모',
          col3: '1',
          col4: '개인별로 신청해주세요.',
        },
        {
          col1: '2',
          col2: '발등안전화',
          col3: '1',
          col4: '개인별로 신청해주세요.',
        },
        {
          col1: '3',
          col2: '안전 방진장갑',
          col3: '1',
          col4: '개인별로 신청해주세요.',
        },
        {
          col1: '4',
          col2: '방진마스크',
          col3: '1',
          col4: '개인별로 신청해주세요.',
        },
        {
          col1: '6',
          col2: '내전압용 절연복',
          col3: '1',
          col4: '개인별로 신청해주세요.',
        },
      ]
    },
    apprRequest() { 
    },
    completeRequest() { 
    },
    addrowGive() {
      this.grid.data.splice(0, 0, {

      })
    },
    saveRequest() {
    },
    removeGive() {
    },
  },
};
</script>
